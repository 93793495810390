.gradient-light {
    background-size: 100% 100%;
    background-position: 0px 0px;
    background-image: linear-gradient(304deg, #37fdadff 0%, #20b2f1ff 100%);
}

.gradient-light-lighter {
    background-size: 100% 100%;
    background-position: 0px 0px;
    background-image: linear-gradient(304deg, #a0ffd7ff 0%, #7fd1f3ff 100%);
}

.gradient-dark {
    background-size: 100% 100%;
    background-position: 0px 0px;
    background-image: linear-gradient(304deg, #0f412fff 0%, #0e5272ff 100%);
}
.gradient-dark-darker {
    background-size: 100% 100%;
    background-position: 0px 0px;
    background-image: linear-gradient(304deg, #051911ff 0%, #072e3fff 100%);
}
.gradient-dark-darker-important {
    background-size: 100% 100% !important;
    background-position: 0px 0px !important;
    background-image: linear-gradient(304deg, #051911ff 0%, #072e3fff 100%) !important;
}

.bg-orange {
    background-color: rgb(182, 106, 7) !important;
}

.bg-blue {
    background-color: rgb(33, 120, 126) !important;
}

.bg-green {
    background-color: rgb(31, 172, 115) !important;
}

.bg-green-hover:hover {
    background-color: rgb(24, 141, 94) !important;
}

.bg-purple {
    background-color: rgb(117, 90, 168) !important;
}

.bg-purple-hover:hover {
    background-color: rgb(99, 76, 141) !important;
}

.bg-pink {
    background-color: rgb(245, 37, 227) !important;
}

.bg-pink-hover:hover {
    background-color: rgb(190, 27, 177) !important;
}

.bg-yellow {
    background-color: rgb(207, 245, 37) !important;
}

.bg-yellow-hover:hover {
    background-color: rgb(170, 201, 34) !important;
}

.text-stroke {
    color: transparent !important;
    -webkit-text-stroke: 1px white;
}

.screen-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes glowing {
    0% {
        filter: drop-shadow(0px 0px 1px #0eff9a);
    }
    25% {
        filter: drop-shadow(0px 0px 3px #cf0eff);
    }
    33% {
        filter: drop-shadow(0px 0px 2px #0eff9a);
    }
    45% {
        filter: drop-shadow(0px 0px 4px #cf0eff);
    }
    53% {
        filter: drop-shadow(0px 0px 2px #0eff9a);
    }
    60% {
        filter: drop-shadow(0px 0px 5px #cf0eff);
    }
    65% {
        filter: drop-shadow(0px 0px 3px #cf0eff);
    }
    75% {
        filter: drop-shadow(0px 0px 0px #cf0eff);
    }
    76% {
        filter: drop-shadow(0px 0px 1px #cf0eff);
    }
    90% {
        filter: drop-shadow(0px 0px 5px #cf0eff);
    }
    100% {
        filter: drop-shadow(0px 0px 1px #cf0eff);
    }
}
.glow {
    animation: glowing 3300ms infinite;
}
.moving-gradient-light {
    background: linear-gradient(304deg, #77ffc9, #6cd3ff, #ff50d3, #6cd3ff, #77ffc9);
    background-size: 800% 800%;
    animation: MovingGradient 2500ms ease infinite;
}

.moving-gradient-light-hover:hover {
    background: linear-gradient(304deg, #77ffc9, #6cd3ff, #ff50d3, #6cd3ff, #77ffc9);
    background-size: 800% 800%;
    animation: MovingGradient 2500ms ease infinite;
}
@keyframes MovingGradient {
    0% {
        background-position: 20% 0%;
    }
    50% {
        background-position: 80% 100%;
    }
    100% {
        background-position: 20% 0%;
    }
}

.moving-gradient-dark {
    background: linear-gradient(
        125deg,
        #010b09,
        #144138,
        #371c40,
        #0a243a,
        #000000,
        #184133,
        #321a00,
        #3d111e,
        #3d1134,
        #3d1134,
        #091c47,
        #3e4709,
        #093f47,
        #470909,
        #094745
    );
    background-size: 3000% 3000%;

    animation: MovingGradientDark 15s ease infinite;
}

.moving-gradient-dark-hover:hover {
    background: linear-gradient(
        125deg,
        #010b09,
        #144138,
        #371c40,
        #0a243a,
        #000000,
        #184133,
        #321a00,
        #3d111e,
        #3d1134,
        #3d1134,
        #091c47,
        #3e4709,
        #093f47,
        #470909,
        #094745
    );
    background-size: 3000% 3000%;

    animation: MovingGradientDark 15s ease infinite;
}
@keyframes MovingGradientDark {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}
